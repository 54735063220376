import React from 'react';
// import { useRef } from 'react';
import './styles/landing.scss';

const Underpinning = () => {

    // const custom_build = useRef(null);
    const images = require.context('../images/portfolio/underpinning', true);
    let image = images('./' + 4 + '.jpg');

    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='underpinning'>
                        <section className='visuals'>
                            <img src={image} alt='Image 10' className='static-visual'></img>
                        </section>
                        <section className='welcome-desc'>
                            <div className='landing-dec'>
                                <div className='title-desc'>            
                                    Strengthen the foundation of your home
                                </div>
                                <div className = 'detailed-desc' >
                                    Underpinning (in other words, strengthening the existing foundation) is the process of strengthening the current foundation of the building.This might be needed due to various reasons such as change in soil underneath, impact from nearby constructions, etc.
                                    While it might sound scary our experts can do the underpinning so that the building can be long lasting. 
                                </div>
                                <div className='detailed-desc'>
                                    Check out our work under <a href="/portfolio" className='nav__link'>Portfolio</a>
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
};

export default Underpinning;