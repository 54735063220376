// import logo from './logo.svg';
// import './App.css';
import React from "react"
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Routes, Route } from "react-router-dom"
// import { Route, Routes } from "react-router-dom"
// We will create these two pages in a moment
import NavigationComponent from "./pages/components/NavigationComponent/NavigationComponent";
import ScrollToTop from "./pages/components/ScrollToTopComponent/ScrollToTop";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Landscaping from "./pages/Landscaping";
import Renovation from "./pages/Renovations";
import Privacy from "./pages/Privacy";
import CustomHomes from "./pages/CustomHomes";
import Portfolio from "./pages/Portfolio";
import Waterproofing from "./pages/Waterproofing";
import Underpinning from "./pages/Underpinning";
import Footer from "./pages/components/FooterComponent/FooterComponent";
import './pages/styles/main.scss';

export default function App() {
  return (
    <div className="app" id="app">
      <ScrollToTop/>
      <NavigationComponent/>
    
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/portfolio" element={<Portfolio/>} />
          <Route path="services/landscaping" element={<Landscaping/>} />
          <Route path="services/renovation" element={<Renovation/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="services/custom-homes" element={<CustomHomes/>} />
          <Route path="services/waterproofing" element={<Waterproofing/>} />
          <Route path="services/underpinning" element={<Underpinning/>} />
        </Routes>
      <Footer/>
    </div>
  );
};
