import React from 'react';
// import { useRef } from 'react';
import './styles/landing.scss';

const Waterproofing = () => {

    // const custom_build = useRef(null);
    const images = require.context('../images/portfolio/waterproofing', true);
    let image = images('./' + 5 + '.jpg');

    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='waterproofing'>
                        <section className='visuals'>
                            <img src={image} alt='Image 10' className='static-visual'></img>
                        </section>
                        <section className='welcome-desc'>
                            <div className='landing-dec'>
                                <div className='title-desc'>            
                                    Protect your building to be Waterproof
                                </div>
                                <div className = 'detailed-desc' >
                                    Foundation waterproofing involves the prevention of groundwater/moisture penetrating through a building's foundation walls, 
                                    which can result in damage to interior spaces below grade (such as basements), and preventing the avoidable deterioration of the 
                                    foundation structure itself.
                                </div>
                                <div className='detailed-desc'>
                                    Are you seeing mold or leakage? Worry not. We at Karthek & Co. can take care of the issue and fix the waterproofing
                                    problem with absolute precision.
                                </div>
                                <div className='detailed-desc'>
                                    Check out our work under <a href="/portfolio" className='nav__link'>Portfolio</a>
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
};

export default Waterproofing;