import React from 'react';
import { appendImages } from './utilities/utility.js';
import './styles/landing.scss';
import './styles/portfolio.scss';

const Portfolio = () => {

    const images = require.context('../images/portfolio', true);
    // console.log('images = ', images);

    const loadImages = () => {

        let images_list = [];
        for(let j=1; j<=14; j++) {
            const image_path = images('./' + j + '.jpg');
            images_list.push(image_path);
        }

        return images_list;
    };

    const loadLandscapeImages = () => {

        let images_list = [];
        for(let j=1; j<=17; j++) {
            const image_path = images('./landscape/' + j + '.jpg');
            images_list.push(image_path);
        }

        return images_list;
    };

    const loadWaterproofingImages = () => {

        let images_list = [];
        for(let j=1; j<=5; j++) {
            const image_path = images('./waterproofing/' + j + '.jpg');
            images_list.push(image_path);
        }

        return images_list;
    };

    const loadUnderpinningImages = () => {

        let images_list = [];
        for(let j=1; j<=2; j++) {
            const image_path = images('./underpinning/' + j + '.jpg');
            images_list.push(image_path);
        }

        return images_list;
    };
    
    const loadComparisonImages = () => {

        let images_list = [];
        for(let j=1; j<=6; j++) {
            const image_path_before = images('./compare/' + j + 'b.jpg');
            images_list.push(image_path_before);
            const image_path_after = images('./compare/' + j + 'a.jpg');
            images_list.push(image_path_after);
        }

        return images_list;
    };

    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='portfolio'>
                        <div className='portfolio-section-title' href='#interior'>Interior</div>
                        <div className='portfolio-visuals'>
                            {appendImages(loadImages())}
                        </div>  
                        <div className='portfolio-section-title' href='#landscaping'>Landscaping</div>
                        <div className='landscape portfolio-visuals'>
                            {appendImages(loadLandscapeImages())}
                        </div>
                        <div className='portfolio-section-title' href='#waterproofing'>Waterproofing</div>
                        <div className='landscaping portfolio-visuals'>
                            {appendImages(loadWaterproofingImages())}
                        </div>
                        <div className='portfolio-section-title' href='#underpinning'>Underpinning</div>
                        <div className='landscaping portfolio-visuals'>
                            {appendImages(loadUnderpinningImages())}
                        </div>
                        <div className='portfolio-section-title' href='#transformation'>Transformation</div>    
                        <div className='comparison portfolio-visuals'>
                            {appendImages(loadComparisonImages(), true)}
                        </div>               
                    </section>
                </section>
            </section>
        </main>
    );

};

export default Portfolio;